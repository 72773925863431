<template>
  <div
    :class="`${
      $store.getters['display/getAside'] ? 'contents expanded' : 'contents'
    }`"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <Breadcrumb :options="breadcrumbOptions" />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12 mb-30">
          <div class="card">
            <div class="my-card-header color-dark fw-500 pt-3">
              <span class="float-left py-2">
                <span class="h4 px-3">
                  {{ t("filter", {}, { locale: getLocal }) }}
                </span>
              </span>
              <span class="float-right"> </span>
            </div>
            <div class="card-body   m-0 p-0">
              <div class="userDatatable global-shadow border-0 bg-white w-100">
                <!-- ------------------------------------ d-lg-block------------------------- -->
                <div class="table-responsive 	d-none d-xl-block">
                  <table class="table mb-0 table-borderless">
                    <thead>
                      <tr class="userDatatable-header">
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("id", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("name", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("username", {}, { locale: getLocal }) }}
                          </p>
                        </th>

                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("mobile", {}, { locale: getLocal }) }}
                          </p>
                        </th>

                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("isadmin", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("status", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("creat", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("upat", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("setstt", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                        <th>
                          <p class="userDatatable-title text-center m-0">
                            {{ t("edit", {}, { locale: getLocal }) }}
                          </p>
                        </th>
                      </tr>
                    </thead>
                    <tbody id="table_tbody">
                      <tr v-for="(e, index) in listData" :key="index">
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.id ? e.id : "-" }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.name ? e.name : "-" }}
                          </div>
                        </td>

                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.username ? e.username : "-" }}
                          </div>
                        </td>

                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.mobile ? e.mobile : "-" }}
                          </div>
                        </td>

                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.isAdmin ? e.isAdmin : "-" }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.status ? e.status : "-" }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.createdAt ? e.createdAt : "-" }}
                          </div>
                        </td>
                        <td>
                          <div class="userDatatable-content text-center">
                            {{ e.updatedAt ? e.updatedAt : "-" }}
                          </div>
                        </td>

                        <td>
                          <a
                            v-if="$store.getters['auth/getUser']"
                            href="#"
                            @click="SwalselectStatusAdmin(e, 'Admin')"
                            class="btn-action btn-action-admin"
                            >{{ t("admin", {}, { locale: getLocal }) }}</a
                          >

                          <a
                            v-if="$store.getters['auth/getUser']"
                            href="#"
                            @click="SwalselectStatusSuspend(e, 'Suspend')"
                            class="btn-action btn-action-suspend"
                            >{{ t("suspend", {}, { locale: getLocal }) }}</a
                          >

                          <a
                            v-if="$store.getters['auth/getUser']"
                            href="#"
                            @click="SwalselectStatusUnSuspend(e, 'Un Suspend')"
                            class="btn-action btn-action-unsus"
                            >{{ t("unsuspend", {}, { locale: getLocal }) }}</a
                          >
                        </td>

                        <td>
                          <a v-if="$store.getters['auth/getUser']">
                            <a href="#" @click="editForm(e)" class="edit">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-edit"
                              >
                                <path
                                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                ></path>
                                <path
                                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                ></path></svg
                            ></a>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- ------------------------------------end d-lg-block------------------------- -->
                <!-- ----------------------- size md -------------------- -->
                <div class="row w-100 d-none d-md-block d-lg-block d-xl-none">
                  <div class="col-12 mymd">
                    <table class="table table-responsive mb-0 table-borderless">
                      <!-- ------------------ head ------------------- -->
                      <thead>
                        <tr class="userDatatable-header">
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("id", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <div class="row">
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("name", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("username", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("mobile", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                            </div>
                          </th>
                          <!-- <th></th>

                          <th></th> -->

                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("isadmin", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("status", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <div class="row">
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("creat", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("upat", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                            </div>
                          </th>
                          <!-- <th>
                           
                          </th> -->
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("setstt", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                          <th>
                            <p class="userDatatable-title text-center m-0">
                              {{ t("edit", {}, { locale: getLocal }) }}
                            </p>
                          </th>
                        </tr>
                      </thead>
                      <!-- ----------------- body ------------------- -->
                      <tbody id="table_tbody">
                        <tr v-for="(e, index) in listData" :key="index">
                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.id ? e.id : "-" }}
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{ e.name ? e.name : "-" }}
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{ e.username ? e.username : "-" }}
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{ e.mobile ? e.mobile : "-" }}
                                </div>
                              </div>
                            </div>
                          </td>

                          <!-- <td>
                           
                          </td> -->

                          <!-- <td>
                         
                          </td> -->

                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.isAdmin ? e.isAdmin : "-" }}
                            </div>
                          </td>
                          <td>
                            <div class="userDatatable-content text-center">
                              {{ e.status ? e.status : "-" }}
                            </div>
                          </td>
                          <td>
                            <div class="row">
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{ e.createdAt ? e.createdAt : "-" }}
                                </div>
                              </div>
                              <div class="col-12">
                                <div class="userDatatable-content text-center">
                                  {{ e.updatedAt ? e.updatedAt : "-" }}
                                </div>
                              </div>
                            </div>
                          </td>
                          <!-- <td></td> -->

                          <td>
                            <div class="row text-center">
                              <div class="col-12 mb-1">
                                <button
                                  v-if="$store.getters['auth/getUser']"
                                  href="#"
                                  @click="SwalselectStatusAdmin(e, 'Admin')"
                                  class="btn-action btn-action-admin"
                                >
                                  {{ t("admin", {}, { locale: getLocal }) }}
                                </button>
                              </div>
                              <div class="col-12 mb-1">
                                <button
                                  v-if="$store.getters['auth/getUser']"
                                  href="#"
                                  @click="SwalselectStatusSuspend(e, 'Suspend')"
                                  class="btn-action btn-action-suspend"
                                >
                                  {{ t("suspend", {}, { locale: getLocal }) }}
                                </button>
                              </div>
                              <div class="col-12 mb-1">
                                <button
                                  v-if="$store.getters['auth/getUser']"
                                  href="#"
                                  @click="
                                    SwalselectStatusUnSuspend(e, 'Un Suspend')
                                  "
                                  class="btn-action btn-action-unsus"
                                >
                                  {{ t("unsuspend", {}, { locale: getLocal }) }}
                                </button>
                              </div>
                            </div>
                          </td>

                          <td>
                            <a v-if="$store.getters['auth/getUser']">
                              <a href="#" @click="editForm(e)" class="edit">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-edit"
                                >
                                  <path
                                    d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                                  ></path>
                                  <path
                                    d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                                  ></path></svg
                              ></a>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- -----------------------end size md -------------------- -->
                <!-- ----------------------- size sm -------------------- -->
                <div class="row w-100   d-block d-sm-block d-md-none d-lg-none m-0 p-0">
                  <div class="col-12 mymd m-0 p-0">
                    <table
                      class="table  mb-0 mb-3"
                      v-for="(e, index) in listData"
                      :key="index"
                    >
                      <thead>
                        <tr class="userDatatable-header">
                          <th></th>
                        </tr>
                      </thead>

                      <tbody id="table_tbody">
                        <tr class="border_bottom">
                          <td class="p-0 m-0">
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("id", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 border p-3">
                                <div class="userDatatable-content text-center">
                                  {{ e.id ? e.id : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0 border">
                              <div class="col-12 m-0 p-0">
                                <div class="row m-0 p-0">
                                  <div class="col-4">
                                    <p
                                      class="
                                        userDatatable-title
                                        text-center
                                        m-0
                                      "
                                    >
                                      {{ t("name", {}, { locale: getLocal }) }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.name ? e.name : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- ---------------------- -->
                              <div class="col-12 m-0 p-0">
                                <div class="row m-0 p-0">
                                  <div class="col-4">
                                    <p
                                      class="
                                        userDatatable-title
                                        text-center
                                        m-0
                                      "
                                    >
                                      {{
                                        t("username", {}, { locale: getLocal })
                                      }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.username ? e.username : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- ---------------------- -->
                              <div class="col-12 m-0 p-0">
                                <div class="row m-0 p-0">
                                  <div class="col-4">
                                    <p
                                      class="
                                        userDatatable-title
                                        text-center
                                        m-0
                                      "
                                    >
                                      {{
                                        t("mobile", {}, { locale: getLocal })
                                      }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.mobile ? e.mobile : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("isadmin", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{ e.isAdmin ? e.isAdmin : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("status", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 p-3 border">
                                <div class="userDatatable-content text-center">
                                  {{ e.status ? e.status : "-" }}
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                            <div class="row m-0 p-0 border">
                              <div class="col-12 m-0 p-0">
                                <div class="row m-0 p-0">
                                  <div class="col-4">
                                    <p
                                      class="
                                        userDatatable-title
                                        text-center
                                        m-0
                                      "
                                    >
                                      {{ t("creat", {}, { locale: getLocal }) }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.createdAt ? e.createdAt : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- ---------------------- -->
                              <div class="col-12 m-0 p-0">
                                <div class="row">
                                  <div class="col-4">
                                    <p
                                      class="
                                        userDatatable-title
                                        text-center
                                        m-0
                                      "
                                    >
                                      {{ t("upat", {}, { locale: getLocal }) }}
                                    </p>
                                  </div>
                                  <div class="col-8">
                                    <div
                                      class="userDatatable-content text-center"
                                    >
                                      {{ e.updatedAt ? e.updatedAt : "-" }}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- ------------------------------ -->
                            <div class="row m-0 p-0 text-center">
                              <div class="col-12 m-0 p-0 border">
                                <p class="userDatatable-title text-center m-0">
                                  {{ t("setstt", {}, { locale: getLocal }) }}
                                </p>
                              </div>
                              <div class="col-12 m-0 p-0 border">
                                <div class="row m-0 p-0 my-2">
                                  <div class="col-12 mb-1">
                                    <button
                                      v-if="$store.getters['auth/getUser']"
                                      href="#"
                                      @click="SwalselectStatusAdmin(e, 'Admin')"
                                      class="btn-action btn-action-admin"
                                    >
                                      {{ t("admin", {}, { locale: getLocal }) }}
                                    </button>
                                  </div>
                                  <div class="col-12 mb-1">
                                    <button
                                      v-if="$store.getters['auth/getUser']"
                                      href="#"
                                      @click="
                                        SwalselectStatusSuspend(e, 'Suspend')
                                      "
                                      class="btn-action btn-action-suspend"
                                    >
                                      {{
                                        t("suspend", {}, { locale: getLocal })
                                      }}
                                    </button>
                                  </div>
                                  <div class="col-12 mb-1">
                                    <button
                                      v-if="$store.getters['auth/getUser']"
                                      href="#"
                                      @click="
                                        SwalselectStatusUnSuspend(
                                          e,
                                          'Un Suspend'
                                        )
                                      "
                                      class="btn-action btn-action-unsus"
                                    >
                                      {{
                                        t("unsuspend", {}, { locale: getLocal })
                                      }}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- ---------------------- -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- -----------------------end size sm -------------------- -->
                <Pagination
                  ref="PaginationRef"
                  :Count="count"
                  @PaginationReturnData="PaginationReturnData"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalImg :srcImage="showIMGData" />
    <ModalAdd :createData="createData" :options="addOptions" />
    <ModalEdit :editData="editData" :options="editOptions" />
  </div>
</template>

<script>
// @ is an alias to /src

import * as serviceAPI from "../services/API.service";
import * as serviceMain from "../services/main.service";
import * as constant from "../services/constant";
import Pagination from "@/components/Pagination.vue";
import { useI18n } from "vue-i18n";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ModalImg from "@/components/Modal/ModalImg.vue";
import ModalEdit from "@/components/Modal/ModalEdit.vue";
import ModalAdd from "@/components/Modal/ModalAdd.vue";

export default {
  name: "ListLotto",
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  components: {
    Pagination,
    Breadcrumb,
    ModalImg,
    ModalEdit,
    ModalAdd,
  },
  data: function () {
    return {
      breadcrumbOptions: {
        isShowRefresh: true,
        isShowStartSearch: true,
        isShowAdd: true,
        pageKey: "userlist",
        buttonAddKey: "adduser",
        refresh: () => this.Refresh(),
        startSearch: () => this.startSearch(),
        showModalAdd: () => this.showModalAdd(),
      },
      editOptions: {
        type: "UserList",
        modalKey: "edituser",
        onFileChange: (event) => this.onFileChangeedit(event),
        sendEditData: (event) => this.sendEditData(event),
      },
      addOptions: {
        type: "UserList",
        modalKey: "adduser",
        onFileChange: (event) => this.onFileChange(event),
        sendCreateData: (event) => this.sendCreateData(event),
      },
      onSearch: false,
      count: 0,
      PaginationData: {},

      imgPath: constant.imageUrl,
      selectedOne: "",
      errors: [],
      accessData: [],
      accessDataCustomer: [],
      companyData: [],
      listData: [],
      selectedID: [],
      createData: {
        name: "",
        mobile: "",
        username: "",
        password: "",
      },
      editData: {
        name: "",
        mobile: "",
        username: "",
        password: "",
      },
      showIMGData: "",
    };
  },
  mounted() {
    this.showData();
    // this.getCount();
    // this.getAccessDataCustomer();
  },
  methods: {
    async Refresh() {
      this.count = 0;
      this.onSearch = false;

      this.showData();
    },
    async startSearch() {
      this.count = 0;
      this.onSearch = true;
      this.showData();
    },
    PaginationReturnData(para_val) {
      this.PaginationData = para_val;

      this.showData();
    },
    // async getCount(){

    //     try {
    //         let data = {
    //             SEARCH:this.keySearch,
    //             OFFSET:this.PaginationData.offset,
    //             RWS:this.PaginationData.limit
    //         }

    //         let getURL =  this.onSearch ? 'loanagreement/countloanagreementsearch':'loanagreement/countloanagreement'
    //         let getAPI = await serviceAPI.call_API('get',getURL,data,'auth');
    //         this.count = getAPI.data.data[0].count
    //     } catch (error) {
    //         serviceMain.showErrorAlert(this,error)
    //     }
    // },

    async showData() {
      try {
        let data = {
          start: this.PaginationData.offset,
          length: this.PaginationData.limit,
          // start:'0',
          // length:'10',
        };
        let getAPI = await serviceAPI.call_API(
          "get",
          "lotto/api/v1/listUsers",
          data,
          "auth"
        );
        this.count = getAPI.data.recordsFiltered;
        this.listData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async confirmUser(e) {
      try {
        let form = new FormData();
        form.append("mobile", createData.MOBILE);
        form.append("name", createData.NAME);
        form.append("username", createData.USERNAME);
        form.append("password", createData.PASSWORD);
        let getData = await serviceAPI.call_API_FORMDATA(
          "POST",
          "lotto/api/v1/createUser",
          form
        );
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async showIMG(e) {
      $("#modal-image").modal("show");
      this.showIMGData = e.image;
    },
    async SwalselectStatusAdmin(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `You won't be able to ${para_status} this!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${para_status} it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatusAdmin(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your is safe :)",
              "error"
            );
          }
        });
    },
    async SwalselectStatusSuspend(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `You won't be able to ${para_status} this!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${para_status} it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatusSuspend(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your is safe :)",
              "error"
            );
          }
        });
    },
    async SwalselectStatusUnSuspend(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: `You won't be able to ${para_status} this!`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes, ${para_status} it!`,
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatusUnSuspend(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your is safe :)",
              "error"
            );
          }
        });
    },
    async selectStatusAdmin(e) {
      try {
        let data = {
          userId: e.id,
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "lotto/api/v1/setUserAsAdmin",
          data,
          "auth"
        );
        console.log(getAPI);
        this.Refresh();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async selectStatusSuspend(e) {
      try {
        let data = {
          userId: e.id,
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "lotto/api/v1/suspendAccount",
          data,
          "auth"
        );

        this.Refresh();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async selectStatusUnSuspend(e) {
      try {
        let data = {
          userId: e.id,
        };
        let getAPI = await serviceAPI.call_API(
          "post",
          "lotto/api/v1/unsuspendAccount",
          data,
          "auth"
        );

        this.Refresh();
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },

    async SwalDeleteUser(e) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.DeleteUser(e);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalCancel(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Cancel this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Cancel it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalClose(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Close this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Close it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async SwalDelete(e, para_status) {
      let swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to Delete this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.selectStatus(e, para_status);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your user is safe :)",
              "error"
            );
          }
        });
    },
    async DeleteUser(e) {
      try {
        // let getFileName = para_img.split("/img/")[1] ;
        // if(!getFileName){
        //     getFileName='';
        // }
        // https://eastest.s3.ap-southeast-1.amazonaws.com/img/8b05a26c-1a3b-42b0-8a78-ae450db98ee7.png
        let data = { LOANID: e.id };
        let getAPI = await serviceAPI.call_API(
          "delete",
          "loanagreement/crudloanagreement",
          data,
          "auth"
        );
        let getData = getAPI.data.data;
        this.Refresh();
        this.$swal.fire("Deleted!", "Your user has been deleted.", "success");
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    async getAccessData() {
      try {
        let data = {};
        let getAPI = await serviceAPI.call_API(
          "get",
          "user/getaccessdata",
          data,
          "auth"
        );
        this.accessData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },

    async getAccessDataCustomer() {
      try {
        let data = {
          mode: "all",
          ID: "1",
          OFFSET: "0",
          RWS: "0",
        };
        let getAPI = await serviceAPI.call_API(
          "get",
          "customer/crudcustomer",
          data,
          "auth"
        );
        this.accessDataCustomer = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this, error);
      }
    },
    // async getCompanyData(){
    //     try {
    //         let data = {}
    //         let getAPI = await serviceAPI.call_API('post','getallcompany',data,'auth');
    //         this.companyData = getAPI.data.data
    //     } catch (error) {
    //         serviceMain.showErrorAlert(this,error)
    //     }
    // },

    async checkForm(e) {
      e.preventDefault();

      if (
        this.createData.ID &&
        this.createData.BANKID &&
        this.createData.BANKNAME &&
        this.createData.BACCNO &&
        this.createData.BACCNA
      ) {
        this.sendCreateData();
        return;
      }

      this.errors = [];

      if (!this.createData.USERNAME) {
        this.errors.push("Username required.");
      }
      if (!this.createData.PASSWORD) {
        this.errors.push("Password required.");
      }
      if (!this.createData.FNAME) {
        this.errors.push("First Name required.");
      }
      if (!this.createData.LNAME) {
        this.errors.push("Last Name required.");
      }
      if (!this.createData.ROLE) {
        this.errors.push("Role required.");
      }
    },
    // uploadImage(e){
    //     let image = e.target.files[0];
    //     let FR= new FileReader();
    //     FR.onload = (ev) => {
    //         let getOringinalImg = ev.target.result;
    //         // this.createData.USER_IMG = ev.target.result;
    //         this.resizedataURL(this,getOringinalImg,100,100)
    //     }
    //     FR.readAsDataURL(image)
    // },
    // resizedataURL(_this,datas, wantedWidth, wantedHeight)
    // {
    //     var img = document.createElement('img');
    //     img.onload = function()
    //         {
    //             var canvas = document.createElement('canvas');
    //             var ctx = canvas.getContext('2d');
    //             canvas.width = wantedWidth;
    //             canvas.height = wantedHeight;
    //             ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
    //             var dataURI = canvas.toDataURL();
    //             console.log("dataURI:")
    //             console.log(dataURI)
    //             _this.createData.USER_IMG = dataURI;
    //         };
    //     img.src = datas;
    // },
    ckbox(para_id) {
      if (this.selectedID.includes(para_id)) {
        this.selectedID = this.selectedID.filter((e) => e !== para_id);
      } else {
        this.selectedID.push(para_id);
      }
    },
    // async editForm(para_username){
    //     $('#modal-edit').modal('show');
    //     for(let e of this.listData){
    //         if(e.username == para_username){
    //             this.editData = {
    //                 USERNAME:e.username,
    //                 PASSWORD:'',
    //                 FNAME:e.name,
    //                 LNAME:e.lname,
    //                 ROLE:e.role_access_id,
    //                 COM_ID:e.company_id,
    //                 USER_IMG:e.user_image,
    //                 IS_ACTIVE:e.is_active
    //             }
    //             break
    //         }
    //     }

    // },
    async sendCreateData(e) {
      try {
        e.preventDefault();
        let form = new FormData();
        form.append("username", this.createData.username);
        form.append("password", this.createData.password);
        form.append("name", this.createData.name);
        form.append("mobile", this.createData.mobile);

        let getAPI = await serviceAPI.call_API_FORMDATA(
          "POST",
          "lotto/api/v1/createUser",
          form
        );

        $("#new-member").modal("hide");
        this.editData.username = "";
        this.editData.password = "";
        this.editData.name = "";
        this.editData.mobile = "";

        let getData = getAPI.status.messageTH;
        serviceMain.showSuccessAlert(this, getData);
        this.Refresh();
      } catch (error) {
        $("#new-member").modal("hide");
        serviceMain.showErrorAlert(this, error);
      }
    },
    async editForm(e) {
      $("#modal-edit").modal("show");
      this.editData = {
        username: e.username,
        password: e.password,
        name: e.name,
        mobile: e.mobile,
      };
    },

    async sendEditData(e) {
      try {
        e.preventDefault();
        let form = new FormData();
        form.append("name", this.editData.name);
        form.append("mobile", this.editData.mobile);

        let getAPI = await serviceAPI.call_API_FORMDATA(
          "POST",
          "lotto/api/v1/editProfile",
          form
        );

        $("#modal-edit").modal("hide");

        let getData = getAPI.status.messageTH;
        serviceMain.showSuccessAlert(this, getData);
        this.Refresh();
      } catch (error) {
        $("#modal-edit").modal("hide");
        serviceMain.showErrorAlert(this, error);
      }
    },
    uploadImageEdit(e) {
      let image = e.target.files[0];
      let FR = new FileReader();
      FR.onload = (ev) => {
        let getOringinalImg = ev.target.result;
        this.resizedataURLEdit(this, getOringinalImg, 100, 100);
      };
      FR.readAsDataURL(image);
    },
    resizedataURLEdit(_this, datas, wantedWidth, wantedHeight) {
      var img = document.createElement("img");
      img.onload = function () {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        canvas.width = wantedWidth;
        canvas.height = wantedHeight;
        ctx.drawImage(this, 0, 0, wantedWidth, wantedHeight);
        var dataURI = canvas.toDataURL();
        console.log("dataURI:");
        console.log(dataURI);
      };
      img.src = datas;
    },
    viewpass() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    showModalAdd() {
      $("#modal-add").modal("show");
    },
  },
  computed: {
    getLocal: function () {
      return this.$store.getters["display/getLocal"];
    },
  },
};
</script>

<style scope>
.mymd table {
  border: 1px solid #ddd;
}
tr.border_bottom td {
  border-bottom: 1px solid #ddd;
}
.table-responsive {
  overflow-y: auto;
  max-height: 70vh;
}
.btn-action-admin {
  background-color: rgb(179, 16, 220);
}
.btn-action-suspend {
  background-color: rgb(199, 27, 4);
}
.btn-action-unsus {
  background-color: rgb(0, 172, 34);
}
.btn-action-view {
  background-color: rgb(3, 117, 238);
}
.btn-action {
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  color: white;
  margin: 0px 5px;
}
.img-size {
  max-height: 100px;
}
</style>